@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html
{
  height: 100% !important;
  min-height: 100% !important;
  overflow-y: auto;
}

body::-webkit-scrollbar
{
  display : none;


}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body
{
  background-image: url("../src/media/revised/gradient_bg.png");
  background-size: inherit;
  height: 100% !important;
  min-height: 100% !important;
}

footer.rounded-lg
{
  border-radius: 0 !important;
}

#\:ra\:, #\:rb\:, #\:rc\:
{
  width: 100% !important
}

@media (min-width: 770px)
{
  .dark\:bg-gray-800
  {
    background: transparent !important;
    padding: 50px 50px !important;
  }

}

@media (max-width: 769px)
{
  .dark\:bg-gray-800
  {
    --tw-bg-opacity: 0.8 !important;
    background-color: rgba(40, 44, 52, --tw-bg-opacity) !important;
  }

}

.text
{
  pointer-events: auto !important;
}

.navybar {
  height: 0.25rem !important;
}

.removeInset {
  height: 0.25rem;
}


  /* Box colors */
.bg-1 {
  background: #ECEFF1;
  color: #37474f;
}
.bg-2 {
  background: #050711;
  color: #ECEFF1;
}
.bg-3 {
  background: #37474f;
  color: #fff;
}

/* Common button styles */
.button {
  float: left;
  min-width: 150px;
  max-width: 75%;
  display: block;
  margin: 1em;
  padding: 1em 2em;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}
.button:focus {
  outline: none;
}
.button > span {
  vertical-align: middle;
}

.bg-1 .button {
  color: #37474f;
  border-color: #37474f;
}
.bg-2 .button {
  color: #ECEFF1;
  border-color: #ECEFF1;
}
.bg-3 .button {
  color: #fff;
  border-color: #fff;
}

/* Sizes */
.button--size-s {
  font-size: 14px;
}
.button--size-m {
  font-size: 16px;
}
.button--size-l {
  font-size: 18px;
}

/* Typography and Roundedness */
.button--text-upper {
  letter-spacing: 2px;
  text-transform: uppercase;
}
.button--text-thin {
  font-weight: 300;
}
.button--text-medium {
  font-weight: 500;
}
.button--text-thick {
  font-weight: 600;
}
.button--round-s {
  border-radius: 5px;
}
.button--round-m {
  border-radius: 15px;
}
.button--round-l {
  border-radius: 40px;
}

/* Borders */
.button--border-thin {
  border: 1px solid;
}
.button--border-medium {
  border: 2px solid;
}
.button--border-thick {
  border: 3px solid;
}


/* Shikoba */
.button.button--shikoba {
  padding: 1em 30px 1em 50px;
  overflow: hidden;
  background: #fff;
  color: #37474f;
  border-color: #37474f;
  -webkit-transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.button--shikoba.button--inverted {
  color: #fff;
  background: #050711;
}
.button--shikoba > span {
  display: inline-block;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--shikoba .button__icon {
  position: absolute;
  left: 20px;
  font-size: 18px;
  -webkit-transform: translate3d(-40px, 2.5em, 0);
  transform: translate3d(-40px, 2.5em, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--shikoba:hover .button__icon,
.button--shikoba:hover > span {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.button--shikoba:hover {
  background: #050711;
  border-color: white;
  color: #fff;
}

/* Nina */
.button--nina {
  padding: 0 2em;
  background: WHITE;
  color: #050711;
  border-color: #37474f;
  overflow: hidden;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.bruh{
  padding: 0 2em;
  background: WHITE;
  width: 100%;
  color: #050711;
  border-color: #37474f;
  overflow: hidden;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.button--nina.button--inverted {
  background: #050711;
  color: white;
}
.button--nina > span {
  display: inline-block;
  padding: 1em 0;
  opacity: 0;
  color: #fff;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--nina::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1em 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--nina:hover {
  background: #050711;
  border-color: white;
  color: #fff;
}
.button--nina:hover::before {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.button--nina:hover > span {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.button--nina:hover > span:nth-child(1) {
  -webkit-transition-delay: 0.045s;
  transition-delay: 0.045s;
}
.button--nina:hover > span:nth-child(2) {
  -webkit-transition-delay: 0.09s;
  transition-delay: 0.09s;
}
.button--nina:hover > span:nth-child(3) {
  -webkit-transition-delay: 0.135s;
  transition-delay: 0.135s;
}
.button--nina:hover > span:nth-child(4) {
  -webkit-transition-delay: 0.18s;
  transition-delay: 0.18s;
}
.button--nina:hover > span:nth-child(5) {
  -webkit-transition-delay: 0.225s;
  transition-delay: 0.225s;
}
.button--nina:hover > span:nth-child(6) {
  -webkit-transition-delay: 0.27s;
  transition-delay: 0.27s;
}
.button--nina:hover > span:nth-child(7) {
  -webkit-transition-delay: 0.315s;
  transition-delay: 0.315s;
}
.button--nina:hover > span:nth-child(8) {
  -webkit-transition-delay: 0.36s;
  transition-delay: 0.36s;
}
.button--nina:hover > span:nth-child(9) {
  -webkit-transition-delay: 0.405s;
  transition-delay: 0.405s;
}
.button--nina:hover > span:nth-child(10) {
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}





